

    import { defineComponent, ref, getCurrentInstance } from 'vue'
    import Button from '../components/Button.vue'
    import Container from '../components/Container.vue'
    import DynamicForm from '../components/Form/DynamicForm.vue'
    import Column from '../components/Grid/Column.vue'
    import Grid from '../components/Grid/Grid.vue'
    import Checkmark from '../components/Icons/Checkmark.vue'
    import PageHeader from '../components/PageHeader.vue'
    import Heading from '../components/Heading.vue'
    import Paragraph from '../components/Paragraph.vue'
    import Projects from '../components/Projects.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import { currentActiveBreakPoint } from '../compositions/UseResponsiveValue'
    import { useTranslation } from '../compositions/UseTranslation'
    import { useCms } from '../compositions/UseCms'
    import { Breakpoint } from '../enums/Breakpoint'
    import { Theme } from '../enums/Theme'
    import { InputType } from '../enums/InputType'
    import { useTheme } from '../compositions/UseTheme'
    import MetaInfo from '../components/MetaInfo.vue'

    export default defineComponent({
        name: 'Contact',
        components: {
            MetaInfo,
            Container,
            PageHeader,
            Heading,
            Grid,
            DynamicForm,
            Projects,
            Column,
            Paragraph,
            Button,
            Checkmark,
            ThemeChanger
        },
        setup() {

            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            // const refreshAOSHard = getCurrentInstance()?.appContext.config.globalProperties.$aos?.refreshHard

            const { contact } = useTranslation()
            const { newContact, contactForm } = useCms()
            const formSubmitted = ref(false)

            return {
                lang: contact,
                cms: newContact,
                contactForm,
                currentActiveBreakPoint,
                Breakpoint,
                Theme,
                formSubmitted,
                InputType,
                resetForm: async function() {

                    formSubmitted.value = false
                    // refreshAOSHard()

                }
            }

        }
    })

