
    import { computed, defineComponent, PropType, ref } from 'vue'
    import Button from '../Button.vue'
    import Input from './Input.vue'
    import CheckboxInput from './CheckboxInput.vue'
    import FileUpload from './FileUpload.vue'
    import { currentActiveBreakPoint } from '../../compositions/UseResponsiveValue'
    import { useValidation } from '../../compositions/form/UseValidation'
    import { email, required } from '../../compositions/form/UseValidators'
    import { useTranslation } from '../../compositions/UseTranslation'
    import { useApi } from '../../compositions/UseApi'
    import { Breakpoint } from '../../enums/Breakpoint'
    import { useCms } from '../../compositions/UseCms'
    import { isCNLocale } from '../../compositions/useI18n'

    enum FieldType {
        Assets = 'assets',
        Checkboxes = 'checkboxes',
        Text = 'text',
        TextArea = 'textarea'
    }

    interface FieldInterface {
        input_type: string,
        display: string,
        instructions: string,
        type: FieldType,
        validate: string[],
        handle: string,
        options?: Record<string, string>, // checkboxes
        default?: string, // checkboxes
        show_filename?: boolean, // file
        max_files?: number // file
    }

    interface FormInterface {
        title: string,
        fields: Record<string, FieldInterface>,
        api_url: string,
        api_endpoint: string,
    }

    export default defineComponent({
        name: 'DynamicForm',
        props: {
            data: { type: Object as PropType<FormInterface>, required: true }
        },
        components: {
            Button,
            Input,
            FileUpload,
            CheckboxInput
        },
        emits: [ 'submit' ],
        setup(props, { emit }) {

            const { about } = useTranslation()
            const formLoading = ref(false)
            const selectedCheckbox = ref<Record<string, string>>()
            let selectedFile: Record<string, File> | null = null

            function getComponent(field: FieldInterface) {

                const validationOptions: Record<string, any> = {
                    required: isCNLocale.value ? required(`请填写${ field.display }`) : required(`${ field.display } required`),
                    email: isCNLocale.value ? email('请提供一个正确的邮箱地址') : email('Not a valid email format')
                }

                const defaults: Record<string, any> = {
                    type: field.type,
                    name: field.handle,
                    placeholder: field.display,
                    validators: field?.validate?.map(validation => validationOptions[validation]) ?? []
                }

                const components: Record<string, any> = {
                    [FieldType.Text]: defaults,
                    [FieldType.TextArea]: {
                        ...defaults,
                        class: 'mt-16'
                    },
                    [FieldType.Assets]: {
                        type: field.type,
                        name: field.handle,
                        text: field.instructions
                    },
                    [FieldType.Checkboxes]: {
                        type: field.type,
                        name: field.handle,
                        options: field.options
                    }
                }

                return components[field.type]

            }

            function setFile(name: string, file: File) {

                selectedFile = { [name]: file }

            }

            function setChecked(name: string, option: string) {

                selectedCheckbox.value = { [name]: option }

            }

            const items = computed(() => {

                return Object.entries(props.data.fields).map((item: any) => {

                    if (item[1].type === FieldType.Checkboxes) {

                        setChecked(item[1].handle, item[1].default)

                    }

                    return getComponent(item[1])

                })

            })

            let validData = {}

            for (const item of items?.value) {

                if (item.validators) {

                    validData = {
                        ...validData,
                        [item.name]: item.validators
                    }

                }

            }

            const { validation, validate, getData } = useValidation(validData)

            return {
                currentActiveBreakPoint,
                Breakpoint,
                items,
                FieldType,
                validation,
                lang: about,
                setFile,
                formLoading,
                setChecked,
                isCNLocale,
                selectedCheckbox,
                async submitForm() {

                    if (!validate() || formLoading.value === true) {

                        return

                    }

                    formLoading.value = true

                    const data = getData()

                    if (selectedFile) {

                        const key = Object.keys(selectedFile)[0]
                        data[key] = selectedFile[key]

                    }

                    if (selectedCheckbox.value) {

                        const key = Object.keys(selectedCheckbox.value)[0]
                        data[key] = selectedCheckbox.value[key]

                    }

                    const response = await useApi().formPost(data, props.data.api_endpoint).catch(err => {

                        console.log(err)
                        formLoading.value = false

                    })

                    if (response?.isErr()) {

                        formLoading.value = false

                    }

                    if (response?.isOk()) {

                        formLoading.value = false
                        emit('success')
                        await scrollTo(0, 0)

                    }

                }
            }

        }
    })
