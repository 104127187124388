import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_DynamicForm = _resolveComponent("DynamicForm")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Container = _resolveComponent("Container")!
  const _component_Checkmark = _resolveComponent("Checkmark")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Pink
    }, {
      default: _withCtx(() => [
        (!_ctx.formSubmitted)
          ? (_openBlock(), _createBlock(_component_Container, {
              key: 0,
              fluid: "",
              class: "pt-32 md:pt-48"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PageHeader, {
                  header: _ctx.cms.heading,
                  subHeader: _ctx.cms.content
                }, null, 8, ["header", "subHeader"]),
                _createVNode(_component_Grid, {
                  columns: [ 2, 6 ],
                  class: "mt-12 md:mt-24"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      span: [ 2, 4 ],
                      push: [ 0, 1 ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DynamicForm, {
                          data: _ctx.contactForm,
                          class: "w-full",
                          onSuccess: _cache[0] || (_cache[0] = ($event: any) => (_ctx.formSubmitted = true))
                        }, null, 8, ["data"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_Container, {
              key: 1,
              fluid: "",
              class: "pt-32 md:pt-40"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      span: [ 2, 4 ],
                      push: [ 0, 1 ],
                      class: "items-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Checkmark),
                        _createVNode(_component_Heading, {
                          tag: "h2",
                          class: "mt-5"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Message successfully sent. ")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Paragraph, {
                          class: "w-full mt-10 text-center text-grey-400",
                          innerHTML: _ctx.lang.message_sent
                        }, null, 8, ["innerHTML"]),
                        _createVNode(_component_Button, {
                          purple: "",
                          class: "mt-12",
                          large: "",
                          onClick: _ctx.resetForm
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.lang.sent_button_text), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
      ]),
      _: 1
    }, 8, ["to"]),
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Dark
    }, {
      default: _withCtx(() => [
        (_ctx.cms.related_cases)
          ? (_openBlock(), _createBlock(_component_Grid, {
              key: 0,
              class: "mt-32 md:mt-48"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AOS, {
                  type: "fade",
                  easing: "ease",
                  duration: 800,
                  delay: 300
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, { span: [ 2, 4 ] }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Paragraph, {
                          innerHTML: _ctx.cms.related_cases_title,
                          heading: ""
                        }, null, 8, ["innerHTML"]),
                        _createVNode(_component_Heading, {
                          tag: "h2",
                          class: "mt-16",
                          innerHTML: _ctx.cms.related_cases_heading
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.cms.related_cases)
          ? (_openBlock(), _createBlock(_component_Projects, {
              key: 1,
              data: _ctx.cms.related_cases,
              class: "mt-24 mb-32"
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.cms.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.cms.seo,
          "structured-data": _ctx.cms.seo_structured_data,
          published: _ctx.cms.date,
          modified: _ctx.cms.last_modified
        }, null, 8, ["seo", "structured-data", "published", "modified"]))
      : _createCommentVNode("", true)
  ], 64))
}