import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "mt-16"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_CheckboxInput = _resolveComponent("CheckboxInput")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.submitForm()), ["prevent"]))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (item.type === _ctx.FieldType.Text || item.type === _ctx.FieldType.TextArea)
          ? (_openBlock(), _createBlock(_component_Input, _mergeProps({ key: 0 }, item, {
              validation: _ctx.validation[item.name]
            }), null, 16, ["validation"]))
          : _createCommentVNode("", true),
        (item.type === _ctx.FieldType.Assets)
          ? (_openBlock(), _createBlock(_component_FileUpload, {
              key: 1,
              text: item.text,
              class: "mt-12 md:mt-12",
              onSelected: ($event: any) => (_ctx.setFile(item.name, $event))
            }, null, 8, ["text", "onSelected"]))
          : _createCommentVNode("", true),
        (item.type === _ctx.FieldType.Checkboxes)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (reason, key, idx) => {
                return (_openBlock(), _createBlock(_component_CheckboxInput, {
                  key: idx,
                  title: reason,
                  class: _normalizeClass({ 'mt-7': index !== 0 && idx !== 0 }),
                  checked: _ctx.selectedCheckbox[item.name] === key,
                  "onUpdated:checked": ($event: any) => (_ctx.setChecked(item.name, key))
                }, null, 8, ["title", "class", "checked", "onUpdated:checked"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    _createVNode(_component_Button, {
      black: "",
      class: "mt-12",
      isLoading: _ctx.formLoading,
      large: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.isCNLocale ? '提交 →' : 'Submit →'), 1)
      ]),
      _: 1
    }, 8, ["isLoading"])
  ], 32))
}